@import '../../variables.scss';

.questionOverviewList {
	list-style: none;
	margin: 10px 0;
	padding: 0;
}

.questionOverviewListItem {
	align-items: flex-start;
	background-color: #ffffff;
	box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.18), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	box-shadow: 0px 2px 6px #00000029;
	display: flex;
	justify-content: space-between;
	margin-bottom: 17px;

	&:hover {
		box-shadow: 0 0 0.1px 1px #edebe9, 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.18), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.11);
	}

	.moreImageButton {
		:global(.ms-Button-flexContainer) {
			background-image: url('../../images/more-icon.svg'), url('../../images/more-icon-hover.svg');
			background-position: center center, -1000px -1000px;
			background-repeat: no-repeat, no-repeat;
			height: 32px;
			width: 32px;

			i {
				display: none;
			}
		}

		:global(.ms-Button) {
			height: 40px;
		}

		&:hover {
			:global(.ms-Button-flexContainer) {
				background-position: -1000px -1000px, center center;
			}
		}
	}
}

.questionOverviewListFlexItem,
.questionOverviewListFlexItemColumn {
	display: flex;

	h4 {
		margin: 0;
		max-width: calc(100% - 142px);
	}
}

.questionOverviewListFlexItem {
	justify-content: space-between;
	width: 100%;

	button,
	button:hover {
		:global(.ms-Button-label),
		:global(.ms-Icon) {
			color: $black_43;
		}

		:global(.ms-Icon-placeHolder) {
			display: none;
		}
	}
}

.rotateFlipIcon i {
	transform: rotate(-180deg) scaleX(-1);
}

.questionOverviewListFlexItemColumn {
	flex-direction: column;
	flex-grow: 1;
	color: $black_43;
	width: calc(100% - 64px);

	h4 {
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		font-size: 14px;
		font-weight: 600;
		justify-content: flex-start;
		line-height: 19px;
		padding: 12px 32px 22px 12px;
		width: 100%;
	}

	.questionOverviewListItemMetadataContainer,
	.questionOverviewListItemMetadata {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.questionOverviewListItemMetadataContainer {
		padding: 0 12px 12px;
		flex-wrap: wrap;
	}

	.questionOverviewListItemMetadata:not(:empty) {
		color: $grey_73;
		font-size: 14px;
		margin-right: 10px;
		height: 20px;

		> * {
			margin-right: 5px;
		}
	}

	.fillContainer {
		cursor: pointer;
		flex-grow: 1;
		flex-shrink: 1;
		height: 100%;
	}
}

.questionOverviewListFlexItemRight {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
}

.playButtonContainer,
.stopButtonContainer {
	background-image: url('../../images/play-circle-outline.svg'), url('../../images/stop-circle-outline.svg');
	background-position: center center, -1000px -1000px;
	background-repeat: no-repeat, no-repeat;
	background-size: 30px;
	box-sizing: border-box;
	height: 30px;
	width: 30px;

	button {
		height: 30px;
		padding: 0;
		width: 30px;

		> span {
			margin-left: -1px;
		}
	}
}

.stopButtonContainer {
	background-position: -1000px -1000px, center center;
}

.stoppedContainer {
	:global {
		.ms-Button--command .ms-Button-flexContainer .ms-Icon,
		.ms-Button--command:hover .ms-Button-flexContainer .ms-Icon {
			font-size: 30px;
			font-weight: bold;
			color: $blue_18;
			margin-right: 0px;
		}
	}
}

.questionOverviewListStartButton {
	align-items: center;
	border-right: 1px solid $grey_f0;
	display: flex;
	justify-content: center;
	width: 62px;
}

:global {
	.ms-Callout {
		button,
		button:hover {
			.ms-ContextualMenu-itemText,
			.ms-ContextualMenu-icon {
				color: $black_43;
			}
		}
	}
}

.buttonGroup {
	justify-content: flex-end;
	margin-top: 5px;
	width: 160px;

	:global {
		.ms-Button--action .ms-Button-flexContainer .ms-Icon,
		.ms-Button--action .ms-Button-flexContainer .ms-Button-label,
		.ms-Button--action:hover .ms-Button-flexContainer .ms-Icon,
		.ms-Button--action:hover .ms-Button-flexContainer .ms-Button-label {
			color: $black_43;
		}
	}
}
