@import '../../variables.scss';
.emptyStartPanel {
	background: $white;
	box-shadow: 0px 2px 6px #00000029;
	border-radius: 4px;
	opacity: 1;
	margin-top: 17px;
	text-align: center;
	overflow: hidden;

	.welcomeTitle {
		padding-top: 20px;
		text-align: center;
		font: normal normal 600 21px/28px Segoe UI;
		letter-spacing: 0px;
		color: $black_43;
	}

	.welcomeText {
		padding-top: 7px;
		text-align: center;
		font: normal normal normal 14px/19px Segoe UI;
		letter-spacing: 0px;
		color: $black_43;
	}

	.actionBoxOuter {
		position: relative;
		left: calc(50% - 414px);
	}

	.actionBoxContainer {
		padding-top: 43px;
		display: flex;
		justify-content: space-between;
		width: 818px;

		.actionBox {
			text-align: center;

			.image {
				background-repeat: no-repeat;
				margin-top: 30px;
			}

			.createQuestionImage {
				background-image: url('../../images/neue\ abstimmung.png');
				width: 228px;
				height: 168px;
			}
			.manageMembersImage {
				background-image: url('../../images/teilnehmer\ verwalten.png');
				width: 229px;
				height: 168px;
			}
			.giveFeedbackImage {
				background-image: url('../../images/feedback.png');
				width: 228px;
				height: 168px;
			}
		}
	}
}

@media only screen and (max-width: 920px) {
	.emptyStartPanel {
		.welcomeTitle {
		}

		.welcomeText {
		}

		.actionBoxOuter {
			left: 0px;
		}

		.actionBoxContainer {
			flex-direction: column;
			width: 100%;
			padding: 20px 0 10px 0;

			.actionBox {
				text-align: center;
				padding: 10px 0 10px 0;

				button {
					width: 209px;
				}

				.image {
					display: none;
				}
			}
		}
	}
}
